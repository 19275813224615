import React from "react";
import "../../styles/gst.css";
import Testimonial from "../../components/Testimonial";
import FAQAccord from "../../components/GFlush";
import Layout from "../../components/Layout";
import { Seo } from "../../components/seo";
import Counter from "../../components/Counter";
import Guidance from "../../components/GSTTemp/Guidance";
// import Hero from "../../components/Hero";
import AboutWithForm from "../../components/GSTTemp/AboutWithForm";
import ScrollNav from "../../components/GSTTemp/ScrollNav";
import ImgContent from "../../components/GSTTemp/ImgContent";
import ContentForm from "../../components/GSTTemp/ContentForm";
import Cta from "../../components/Cta";
import SliderContent from "../../components/GSTTemp/SliderContent";
import ContentImg from "../../components/GSTTemp/ContentImg";
import Package from "../../components/GSTTemp/Package";

export default function PatentRegistration() {
  /* Slider */
  // const patSlider = [
  //   {
  //     header_color: false,
  //     content_color: false,
  //     marginTop: 30,
  //     background: `linear-gradient(139.4deg, rgb(207, 249, 216) 28.54%, rgb(243, 238, 160) 104.43%)`,
  //     header: `Online patent registration`,
  //     content: `Best Online patent registration Service in Hosur`,
  //     image: "/imgs/registration/ipr/td3.png",
  //     alt_tag: "Best Online patent registration in Hosur",
  //   },
  // ];

  /* Package Data */
  const PackageCompData = {
    header: `Patent Registration`,
    buyBtnLink: `#pricing-buy`,
    price: `59999`,
    content: [
      {
        points: `PAN & TAN Registration `,
        icon: "/imgs/icons/gst.png",
        alt_tag: "PAN & TAN Registration",
        background: "",
        plus: "+",
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: "/imgs/icons/itr_filing.png",
        alt_tag: "TODAYFILINGS ITR E-filing service",
        background: "bg-f78acb",
        plus: "+",
        plus_dnone: "d-none d-md-block",
      },
      {
        points: `MSME Registration`,
        icon: "/imgs/icons/enterprises.png",
        alt_tag: "MSME Registration",
        background: "bg-eb6b3d",
        plus: "+",
      },
      {
        points: `Tax Consultancy Service `,
        icon: "/imgs/icons/consultant.png",
        alt_tag: "Tax Consultancy Service",
        background: "bg-c679e3",
      },
    ],
  };
  /* About Data With Form*/
  const patAboutData = {
    header: `Online Patent Registration`,
    sub_title: `Patent registration for the business and organizations with TODAYFILINGS experts. `,
    content_paragraph: [
      // `A formal document granting an inventor exclusive rights to a creative innovation or a person or company getting
      //  intellectual property rights to their creative work are both known as patent rights.`,

      `The patent attorney registration is actually good for around 20 years. The patent can be registered for a wide range
       of procedures, including those involving art, a process or method for manufacturing, a machine, a specific device, a 
       technical application or app, computer software, pharmaceuticals or chemicals, and more.`,
    ],
  };

  /* Scroll Nav Data */
  const patScrollId = [
    {
      id: `#documents`,
      heading: `Documents`,
    },
    {
      id: `#procedure`,
      heading: `Procedure`,
    },
    {
      id: `#type`,
      heading: `Type`,
    },
    {
      id: `#benefits`,
      heading: `Benefits`,
    },
  ];

  /* Img Content Component Data */
  const patIcData = {
    id: 'documents',
    mt_div: '',
    mt_img: '',
    header: 'Patent Registration Required Documents',
    image: '/imgs/registration/ipr/td6.png',
    alt_tag: 'Patent Registration',
    points: [
      {
        head: '',
        content: `Required patent registration application forms`,
        icon: true,
      },
      {
        head: '',
        content: `Proof of the right to register the patent form.`,
        icon: true,
      },
      {
        head: '',
        content: `If applicable section-8 report and undertaking in form-3`,
        icon: true,
      },
      {
        head: '',
        content: `If appropriate, a Start-Up India Certificate or an MSME`,
        icon: true,
      },
      {
        head: '',
        content: `When biological materials are used in an innovation, the geographical origin must also be mentioned.`,
        icon: true,
      },
    ],
  };

  /* Content Form Component Data */
  const patCfData = {
    id: 'procedure',
    mt_div: 'mt-5',
    mt_img: 'mt-5',
    header: 'The Process For Registering A Patent In India',
    content: ``,
    body: [
      {
        head: `Patent Registration Procedure`,
        points: `The following is a description of the patent registration process in India.`,
        icon: true,
      },
      {
        head: `Effective date:`,
        points: `Looking up patents,Patent drafting,Journal Streaming.`,
        icon: true,
      },
      {
        head: `Before the Rejection`,
        points: `According to Section 25(1) of the Patent (Amendment) Act 2005, anyone can file a dispute.`,
        icon: true,
      },
      {
        head: ``,
        points: `Previously communicated`,
        icon: true,
      },
      {
        head: ``,
        points: `Non-patentability`,
        icon: true,
      },
      {
        head: ``,
        points: `Accuracy and sufficiency of description`,
        icon: true,
      },
      {
        head: ``,
        points: `holding the priority incorrectly`,
        icon: true,
      },
      {
        head: `Patent Inspection`,
        points: `The process of registering a patent includes a crucial stage called patent examination. 
        The patent examination comes next after the request is published. Since the process is not automated, 
        the patent registration application must request inspection or patent examination within 48 months after
         the priority date or the registration date of the request, whichever occurs first.`,
        icon: true,
      },
      {
        head: `Authorize`,
        points: `If all the necessary documentation are submitted and the patent is in compliance with the law,
         it will be granted. The patent registration is good for 20 years.`,
        icon: true,
      },
    ],
  };

  /* ImgSlider Data */
  const patImages = [
    '/imgs/registration/ngo/trust.png',
    '/imgs/registration/msme/msme-reg.png',
    '/imgs/registration/shop/shop-img-slide.png',
  ];

  /*  Slider Content Component Data */
  const patSCData = {
    id: 'type',
    background: [],
    mt_div: 'mt-3',
    header: 'Patent Registration Applications Of Various Types',
    content: [
      {
        points: `Fundamental Use,International PCT Application`,
        icon: true,
      },
      {
        points: `Formalized Form,National Phase PCT Application`,
        icon: true,
      },
      {
        points: `Registration address,Application of Services`,
        icon: true,
      },
      {
        points: `Novelty,`,
        icon: true,
      },
      {
        points: `Inventiveness`,
        icon: true,
      },
      {
        points: `Innovative Step`,
        icon: true,
      },
    ],
    images: patImages,
  };

  /*  Content Img Component Data */
  const paTCiData = {
    id: 'benefits',
    background: 'main-content',
    mt_div: 'mt-3',
    header: 'Patent Registration Advantages',
    paragraph: ``,
    points: [
      {
        content: `The fact that the creator owns all the rights to the invention deters competitors.`,
        icon: true,
      },
      {
        content: `As it changes the patent holder to be accredited with premium advantage for the invention, 
        it improves the expansion of your firm and the interests.`,
        icon: true,
      },
      {
        content: `It makes sense to lease or trade certain patents and registration offices, just like it makes 
        sense to do so with other sorts of property.`,
        icon: true,
      },
      {
        content: `If you're willing to sell or license your patent, it can help you raise money for your company.`,
        icon: true,
      },
      {
        content: `The patent holder may also choose who to provide authorization to utilize the invention to, as long 
        as they abide by the agreed-upon terms.`,
        icon: true,
      },
      {
        content: `Can file a lawsuit against a third party if a violation occurs and worsens your reputation in the
         business world`,
        icon: true,
      },
    ],
    image: '/imgs/business/requirements.png',
    alt_tag: 'Required Documents for MsMe Registration',
  };

  var FAQ_data = [
    {
      header:
        'Once submitted, is a patent registration application automatically inspected?',
      body: [
        {
          content: `After filing, a patent registration application is not immediately
          scrutinized.`,
          icon: false,
        },
      ],
    },

    {
      header: 'Does India have any International Depositary Authorities?',
      body: [
        {
          content: `Yes, Chandigarh, India, has a newly established "International
          Depositary Authority" that is recognized as the Institute of
          Microbial Technology (IMTECH).`,
          icon: false,
        },
      ],
    },
    {
      header:
        'Is there a place in India where one can submit a patent application?',
      body: [
        {
          content: `Yes, India has four departments or agencies dedicated to patents,
          and they are located in Kolkata, Mumbai, New Delhi, and Chennai.`,
          icon: false,
        },
      ],
    },
    {
      header:
        ' Is there a cost associated with registering a description like that for pre-grant opposition?',
      body: [
        {
          content: `No cost is required to file a description for pre-grant objection;
          anyone may do so.`,
          icon: false,
        },
      ],
    },
    {
      header: 'What justifications can you use to file a pre-grant opposition?',
      body: [
        {
          content: `Section 25(1) of the Patents Act of 1970 admits the geographical
          areas for pre-grant opposition filing.`,
          icon: false,
        },
      ],
    },
    {
      header: 'What does a patent do?',
      body: [
        {
          content: `An individual or firm obtains the intellectual property right of an invention, the patent 
          right grants full rights over making, using, selling, or importing the products and services and also 
          restricts others from doing so.`,
          icon: false,
        },
      ],
    },
    {
      header: 'How to obtain Patent registration?',
      body: [
        {
          content: `Following steps need to be followed to obtain patent registration. Patentability/ Novelty 
          research Drafting the patent application Patent application filing Patent application publication Examination
           Examination report issuance Granting the patent.`,
          icon: false,
        },
      ],
    },
    {
      header: ' Who can get patent registration?',
      body: [
        {
          content: `The following can obtain patent registration: Art, process, method, or manner of the manufacture
           Machine, apparatus Any products produced by manufacturing Computer software with technical application And 
           even product paten for food, chemicals, medicines or drugs.`,
          icon: false,
        },
      ],
    },
    {
      header: ' For how long is the patent registration valid?',
      body: [
        {
          content: `Patent registration is valid for 20 years.`,
          icon: false,
        },
      ],
    },
    {
      header: 'What information is required to obtain Patent registration?',
      body: [
        {
          content: `Patent registration application should include the details of the invention in the form of a
           CD or a pen drive that has mentioned the name, the uses, and the data of the invention.`,
          icon: false,
        },
      ],
    },
  ];
  return (
    <div>
      <Seo
        title='Patent Registration'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />
      <Layout>
        <main id='main'>
          {/* <Hero sliders={patSlider} /> */}
          <Package packageData={PackageCompData} />

          <AboutWithForm about_content={patAboutData} />

          <Counter />
          <ScrollNav scroll_data={patScrollId} />
          <ImgContent item={patIcData} />
          <ContentForm CFSection_data={patCfData} />
          <SliderContent ScSection_data={patSCData} />
          <ContentImg CISection_data={paTCiData} />

          <Cta />
          <Guidance />
          <Testimonial />
          <FAQAccord items={FAQ_data} />
        </main>
      </Layout>
    </div>
  );
}
